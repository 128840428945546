import React from 'react';
import DownloadSamples from './download-samples';


function BJFStarsBanner() {
    return (
        <div>
            <section className='py-5 text-center navyBanner'>
                <div className='bannerWrap'>
                    <h1 className='mb-4'>BJF STARS</h1>
                    <p className='small'>Bhai Jaitajee Foundation invites applications from brilliant students of Class X in Punjab for two-year residential coaching for IIT JEE &amp; NEET (entrance exams for admission to medical and engineering colleges) &amp; schooling.</p>
                    <p>The coaching under this program is Free.</p>
                    
                    <div className='pt-3 d-flex flex-wrap justify-content-center'>
                        <div className='p-2'>
                            <a href="/admission/apply-online1" className="btn btn-primary btn-lg">Apply Online</a>
                        </div>
                        <div className='p-2'>
                            <a href="https://forms.gle/8nVkNvZbpr6xNKRz7" target='_blank' class="btn btn-lg btn-primary">Google Form BJF Stars 2027</a>
                        </div>
                        <div className='p-2'>
                            <a href="/pdf/2024-November/BJF-Stars-2027-Notification.pdf" download="BJF-Stars-2025-27-Notification" class="btn btn-primary btn-lg">Notification</a>
                        </div>                      
                        {/* <a href="/pdf/app-forms/v2/Application-form_BJF Stars-2022-2024.pdf" download="Application Form" class="btn btn-lg mx-3 btn-outline-secondary">Download Form</a> */}

                    </div>
                    <div className='dnldSamples text-center'>
                        <a href="/social-materials" class="btn btn-lg btn-outline-secondary mt-2">BJF Stars 2024-26: Social Awareness Test Study Material</a>
                        <p className='small pt-3 m-0'>This material is indicative only. The students should read about the mentioned areas.</p>
                    </div>
                    {/* <p className='small mute'><em>(The last date of application for admission to BJF Stars 2024-26 has been extended to 25th January 2024.)</em></p> */}
                    <DownloadSamples />
                </div>
            </section>
        </div>
    )
}

export default BJFStarsBanner