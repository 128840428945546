import React, { useEffect } from 'react';
import BJFRepeatersBanner from './banner.js';
import Footer from '../../../Footer.js';


function BJFRepeaters() {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>

            <BJFRepeatersBanner />

            <section className='py-5 text-primary'>
                <div className='mw800p'>
                    <p className='mb-3'>Bhai Jaitajee Foundation India invites applications for BJF Repeaters Batch providing coaching for NEET to eligible students who have cleared XII. The students will be selected on the basis of written test and/or interview for a fully residential program of approximately 1 year out of which meritorious students from economically weaker sections & rural areas will be awarded upto 100% scholarship.</p>
                    <p>47 Students have been taught under this program out of which 35 students have qualified for various prestigious institutes including 27 in AIIMS, PGIMER Chandigarh and Govt Medical Colleges for MBBS, BDS and B.Sc. Nursing, 3 in IISER etc.</p>
                </div>
            </section>

            <section className='py-5 grayBoxTB text-primary'>
                <div className='container-lg'>
                    <h2 className='text-center mb-3'>Eligibility Criteria</h2>
                    <p className='text-center mb-5'>The applicant should be a resident of Punjab or Chandigarh.</p>
                    <div className='p-4 bdrA rounded-2'>
                        <div className="row g-5 align-items-center">
                            <div className="col-12 col-md-5">
                                <div className='px-lg-2'>
                                    <p><label className='d-inline-block uLStyle'>Previous Year's NEET Score:</label></p>
                                    <p className='m-0'>Minimum 300 (GEN/OBC) / 280 (SC/ST) for those who have appeared in NEET earlier</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 text-center'><small>or</small></div>
                            <div className="col-12 col-md-5">
                                <div className='px-lg-2'>
                                    <p><label className='d-inline-block uLStyle'>XII Score:</label></p>
                                    <p className='m-0'>Minimum 85% for those who have not appeared in NEET earlier</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='text-center py-4'>
                <a href="/pdf/2024-December/BJF Repeaters 2026 Application Form.pdf" download="Application Form" class="btn btn-outline-secondary btn-lg">Click here to download the application form</a>
            </div>


            <Footer />
        </div>
    )
}

export default BJFRepeaters