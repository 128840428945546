import React, { useEffect } from 'react';
import BJFStarsBanner from './banner.js';
import Footer from '../../../Footer.js';


function BJFStars () {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>

            <BJFStarsBanner />

            <section className='py-5 infoGraph1 text-primary'>
                <div className='container-lg text-center'>
                    <div className='text-center topCrop'>
                        <img className='img-fluid' src='/admissions/BJF_Stars/cont01.svg' alt="" />
                    </div>
                    <p>These competitions leading to entry into reputed institutes with degrees that provide nearly sure employment - IITs, AIIMS, IISER, NITs, IIITs, Punjab Engineering College (PEC) and other reputed Government Engineering and Medical Colleges.</p>
                    <p>253 students have been taught till date under this program out of which 211 students (83.4%) have been placed in various reputed professional institutes including 29 in IITs, 19 in IIITs, 11 in NITs, and 39 in MBBS in AIIMS and Govt. Medial Colleges, 4 in IISER etc.</p>
                </div>
            </section> 

            <section className='grayBoxTB py-5 text-primary'>
                <div className='container-lg text-center'>
                    <h1 className='mb-4'>Selection Process</h1>
                    <div className='row g-0 justify-content-end selection mb-5'>
                        <div className='col-4 col-lg pt-5 px-1 px-lg-3'>
                            <div className='selBox'>
                                <div className='hdr p-2 px-3'><span>Step</span>01</div>
                                <div className='p-3'>Application for entrance test.</div>
                            </div>
                        </div>
                        <div className='col-4 col-lg pt-5 px-1 px-lg-3'>
                            <div className='selBox'>
                                <div className='hdr p-2 px-3'><span>Step</span>02</div>
                                <div className='p-3'>Entrance test.</div>
                            </div>
                        </div>
                        <div className='col-4 col-lg pt-5 px-1 px-lg-3'>
                            <div className='selBox'>
                                <div className='hdr p-2 px-3'><span>Step</span>03</div>
                                <div className='p-3'>Interview along with aptitude test.</div>
                            </div>
                        </div>
                        <div className='col-4 col-lg pt-5 px-1 px-lg-3'>
                            <div className='selBox'>
                                <div className='hdr p-2 px-3'><span>Step</span>04</div>
                                <div className='p-3'>Selection of Candidates.</div>
                            </div>
                        </div>
                        <div className='col-4 col-lg pt-5 px-1 px-lg-3'>
                            <div className='selBox'>
                                <div className='hdr p-2 px-3'><span>Step</span>05</div>
                                <div className='p-3'>Commencement of course.</div>
                            </div>
                        </div>
                    </div>

                    <p className='text-start px-3'>Students will be selected through a Written Test followed by an Aptitude test, Social Awareness Interview & Parents' Interview. Priority will be given to the students belonging to rural areas and economically weaker sections.</p>
                    <p className='text-start px-3'>Deserving students will get upto 100% scholarship.</p>
                </div>
            </section>

            <section className='py-5 text-primary'>
                <div className='container-lg'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-5'>
                            <img className='img-fluid' src="/admissions/BJF_Stars/cont02.svg" alt="" />
                        </div>
                        <div className='col-12 col-md-7'>
                            <p>Selected students will be admitted to CBSE-affiliated reputed schools of Chandigarh for nonmedical stream and Mohali for medical stream. The schools provide considerable fee concession to deserving students. Very competent teachers will prepare these students for IIT-JEE and Medical entrance tests through regular coaching. Secure and modern hostel facilities (SEPARATE for boys and girls) are available on our premises.</p>
                            <p>Selected students will be given free coaching. Boarding & lodging charges & concession in school fees will depend on the parents' income.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='grayBoxTB py-5 text-primary'>
                <div className='container-lg'>
                    <p>BJF is a unique institution where students are supervised and supported in self-study hours after school and coaching classes. Successful personalities from Engineering, Medicine, Business, Academia, and many other professionals are the mentors to individual students to guide and help them on all fronts. The whole environment here is conducive to studies and all-round growth of students.</p>
                    <p>In highly deserving cases where a candidate supported by BJFI in the above program is selected to join a quality institute for higher studies and cannot afford the cost, she/he is provided interest-free loan/scholarship by BJFI to tide over the difficulties.</p>
                    <div className='text-center pt-4'>
                        <a href="/pdf/2024-November/BJF-Stars-2027-Application-Form.pdf" download="Application Form" class="btn btn-outline-secondary btn-lg">Click here to download the application form</a>
                    </div>
                </div>
            </section>
  

            <Footer />
        </div>
    )
}

export default BJFStars