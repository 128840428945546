import React from 'react';


function BJFRepeatersBanner () {
    return (
        <div>
            <section className='py-5 text-center navyBanner'>
                <div className='bannerWrap'>
                    <h1 className='mb-4'>BJF REPEATERS</h1>
                    <div className='pt-4'>
                        <a href="/admission/apply-online2" className="btn btn-primary btn-lg mx-2 my-1">Apply Online</a>
                        <a href="/pdf/2024-December/BJF Repeaters 2026 Application Form.pdf" download="Application Form" class="btn btn-lg mx-2 my-1 btn-primary">Download Form 2026</a>
                        <a href="/pdf/2024-December/Final_Brief_BJF Repeaters 2026 Notification.pdf" download="Notification" class="btn btn-lg mx-2 my-1 btn-primary">Notification</a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BJFRepeatersBanner